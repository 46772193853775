<template>
  <div class="super-list">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="super-list-cont">
      <div class="super-list-tab">
        <div class="super-list-tab-list">
          <div :class="item.id == curTabId ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{ item.name }}</div>
        </div>
      </div>
      <div v-if="curTabId == 1">
        <div class="search">
          <Input v-model="keyword"
                 :placeholder="$t('common_search_keyword')"
                 clearable
                 search
                 :enter-button="true"
                 @on-search="changeKeyword"
                 style="width: 200px;"
          />
        </div>
        <div class="table">
          <Table border :columns="columns" :data="dataList"></Table>
        </div>
        <div class="page">
          <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
        </div>
      </div>
      <div v-if="curTabId == 2">
        <ActivationList :isManage="true" :isList="true"></ActivationList>
      </div>
    </div>
    <div v-if="addUserModal">
      <AddUser :dataDeatil="curData" @success="addSuccess" @close="closeAdduser"></AddUser>
    </div>
<!--    <Modal-->
<!--        v-model="addUserModal"-->
<!--        :title="$t('codekey_list_add_user')"-->
<!--        width="1000px"-->
<!--        v-if="curData"-->
<!--    >-->
<!--      <div class="add-container">-->
<!--        <div class="tit">-->
<!--&lt;!&ndash;          <p>{{ curData.category && curData.category.name }}-{{curData.secondCategory && curData.secondCategory.name}}</p>&ndash;&gt;-->
<!--          <p>{{$t('codekey_list_surplus')}}：<span>{{ curData.amount - curData.count }}个/{{this.timelongsObj[curData.timelong]}}</span></p>-->
<!--        </div>-->
<!--        <Form :label-width="100" class="company-h" >-->
<!--          <FormItem :label="$t('codekey_manage_add_first_category') + ':'" v-if="curData.category_id">-->
<!--            <div class="category-list">-->
<!--              <div :class="firstCategoryId == item.id ? 'active' : ''" v-for="item in firstCategoryList" :key="item.id + 'first'" @click="changeFirstCategory(item)">{{item.name}}</div>-->
<!--            </div>-->
<!--          </FormItem>-->
<!--          <FormItem :label="$t('codekey_manage_add_second_category') + ':'">-->
<!--            <div class="category-list">-->
<!--              <div :class="secondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'first'" @click="changeSecondCategory(item)">{{item.name}}</div>-->
<!--            </div>-->
<!--          </FormItem>-->
<!--          <FormItem :label="$t('codekey_list_select_dateset') + ':'">-->
<!--            <div class="category-list">-->
<!--              <RadioGroup v-model="userCategory">-->
<!--                <Radio :label="item.name" v-for="item in categoryList" :key="item.id"></Radio>-->
<!--              </RadioGroup>-->
<!--            </div>-->
<!--          </FormItem>-->
<!--          <FormItem :label="$t('codekey_list_mode') + ':'" >-->
<!--            <div class="mode">-->
<!--              <div class="mode-tit">-->
<!--                <RadioGroup v-model="userMode">-->
<!--                  <Radio :label="item" v-for="(item,index) in modeList" :key="index" ></Radio>-->
<!--                </RadioGroup>-->
<!--              </div>-->
<!--              <div class="mode-cont" v-if="userMode == $t('codekey_list_select_user')">-->
<!--                <div class="mode-cont-tit">-->
<!--                  <div>-->
<!--                    <Button type="primary" @click="goInvitation">{{ $t('codekey_list_copy_url') }}</Button>-->
<!--                    <p>{{ $t('codekey_list_selected_user') }}:<span>{{allUserData.length}}</span>人</p>-->
<!--                  </div>-->
<!--                  <div class="mode-cont-r">-->
<!--                    <div class="group-box">-->
<!--                      <p>班级</p>-->
<!--                      <Select v-model="groupId" style="width:200px" clearable @on-change="changeGroup">-->
<!--                        <Option v-for="item in groupList" :value="item.id" :key="item.id">{{ item.name }}</Option>-->
<!--                      </Select>-->
<!--                    </div>-->
<!--                    <Input v-model="userKeyword"-->
<!--                           :placeholder="$t('common_search_keyword')"-->
<!--                           clearable-->
<!--                           search-->
<!--                           :enter-button="true"-->
<!--                           @on-enter="changeUserKeyword"-->
<!--                           @on-search="changeUserKeyword"-->
<!--                           style="width: 200px;"-->
<!--                    />-->
<!--                  </div>-->

<!--                </div>-->
<!--                <div class="table">-->
<!--                  <Table border :columns="userColumns" :data="userDataList" @on-select="selectUser" @on-select-cancel="cancelUser" @on-select-all="selectUser" @on-select-all-cancel="cancelUserAll"></Table>-->
<!--                </div>-->
<!--                <div class="page">-->
<!--                  <MyPage :total="userTotal" :current="userPage" @changePage="changeUserPage" @changePageSize="changeUserPageSize"></MyPage>-->

<!--&lt;!&ndash;                  <Page :total="userTotal" @on-change="changeUserPage" @on-page-size-change="changeUserPageSize" :current="page" show-total show-sizer show-elevator></Page>&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="mode-cont" v-if="userMode == $t('codekey_list_issue')">-->
<!--                {{ $t('codekey_list_need_code_num') }}：<Input v-model="count" type="number" :placeholder="$t('codekey_list_num_placeholder')" style="width: 300px" />-->
<!--              </div>-->
<!--              </div>-->
<!--          </FormItem>-->
<!--        </Form>-->
<!--      </div>-->

<!--      <div slot="footer">-->
<!--        <Button @click="cancelUserModal">{{ $t('codekey_manage_cancel') }}</Button>-->
<!--        <Button type="primary" @click="saveUser">{{ $t('codekey_manage_save') }}</Button>-->
<!--&lt;!&ndash;        <Button type="primary" @click="saveUser">导出激活码</Button>&ndash;&gt;-->
<!--      </div>-->
<!--    </Modal>-->
  </div>
</template>

<script>
import util from '@/utils/tools.js';
import ActivationList from './components/detail/activationList'
// import MyPage from '@/components/myPage'
import AddUser from './components/addUser';
export default {
  name: "list",
  data(){
    return {
      keyword:'',
      columns: [
        {
          title: 'ID',
          key: 'id'
        },
        {
          title: this.$t('codekey_list_active_category'),
          render: (h, params) => {
            return h('router-link', {
              props: {
                to: {
                  name: 'cdkey-detail',
                  params: {
                    id: params.row.id,
                    tab:'list'
                  }
                }
              },
              style:{
                color:'#2db7f5',
              }
            }, (params.row.category ? params.row.category.name : '不限') + '-' + (params.row.secondCategory ? params.row.secondCategory.name : '不限'));
          }
        },
        {
          title: this.$t('codekey_list_code_timelong'),
          key: 'timelong',
          render: (h, params) => {
            return h('div', {}, this.timelongsObj[params.row.timelong]);
          }
        },
        {
          title: this.$t('codekey_list_surplus_num'),
          key: 'amount',
          render: (h, params) => {
            return h('div', {}, params.row.amount - params.row.count);
          }
        },
        {
          title: this.$t('codekey_list_active_user'),
          key: 'used_count',
        },
        {
          title: this.$t('codekey_list_unactive_user'),
          key: 'timelong',
          render: (h, params) => {
            return h('div', {}, params.row.count - params.row.used_count);
          }
        },
        {
          title: this.$t('codekey_list_active_rate'),
          key: 'rate',
          render: (h, params) => {
            return h('div', {}, params.row.count > 0 && params.row.used_count > 0 ? Math.floor(params.row.used_count/params.row.count*100) + '%' : '0%');
          }
        },
        {
          title: this.$t('codekey_list_status'),
          key: 'status',
          render: (h, params) => {
            return h('div', {}, this.statusesObj[params.row.status]);
          }
        },
        {
          title: this.$t('codekey_manage_create_time'),
          key: 'created_at',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.created_at * 1000), 'MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: this.$t('codekey_manage_update_time'),
          key: 'updated_at',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.updated_at * 1000), 'MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: '备注',
          key: 'desc',
          render: (h, para) => {
            return h('span', para.row.desc);
          }
        },
        {
          title: this.$t('codekey_manage_op'),
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  disabled:params.row.status == 2 ? true : false
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.addUser(params.row)
                  }
                }
              }, this.$t('codekey_list_add_user')),
            ]);
          }
        }
      ],
      dataList: [],
      total:0,
      page:1,
      pageSize:10,
      statusesObj:{},
      timelongsObj:{},
      curData:'',
      addUserModal:false,
      categoryList:[],
      userColumns:[
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: 'ID',
          key: 'id'
        },
        {
          title: this.$t('codekey_list_name'),
          key: 'nickname'
        },
        {
          title: this.$t('codekey_list_company'),
          key: 'id',
          render: (h, params) => {
            return h('div', {}, params.row.site.name);
          }
        },
      ],
      userDataList:[],
      userPage:1,
      userPageSize:10,
      userTotal:0,
      userKeyword:'',
      userCategory:'',
      userMode:this.$t('codekey_list_select_user'),
      modeList:[this.$t('codekey_list_select_user'),this.$t('codekey_list_issue')],
      allUserData:[],
      count:'',
      firstCategoryList:[], //一级分类列表
      secondCategoryList:[],  //二级分类列表
      firstCategoryId:'',
      secondCategoryId:'',
      tabList:[
        {
          name:'激活码授权管理',
          id:1
        },
        {
          name:'激活码明细查询',
          id:2
        },
      ],
      curTabId:1,
      groupList:[],
      groupId:'',
    }
  },
  created(){
    this.curTabId=this.$route.query.tabId || 1;
    this.getGroupList();
    this.cdkeyForm(); //获取时长与状态
    this.getList(); //获取激活码列表
  },
  components:{
    ActivationList,
    // MyPage,
    AddUser
  },
  methods:{
    getGroupList(){
      let params = {
        pageSize:99,
        user_id:'-1'
      };

      this.api.user.groupList(params).then((res)=>{
        this.groupList = res.list;
      })
    },
    changeGroup(){
      this.userPage = 1;
      this.getUserList();
    },
    changeTab(data){
      this.curTabId = data.id;
    },
    changeKeyword(){
      this.page = 1;
      this.getList();
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.getList();
    },
    add(){  //新增激活分类
      this.addCategory = true;
    },
    getCategory(firstCategoryId,secondCategoryId){
      let params = {};
      if(firstCategoryId){
        params.category_id = firstCategoryId;
      }
      if(secondCategoryId){
        params.category_second_id = secondCategoryId;
      }

      this.api.dataset.categoryForm(params).then((res)=>{
        this.firstCategoryList = res.categoryFirstList;
        this.secondCategoryList = res.categorySecondList;

        this.categoryList =[
          {
            name:'全部',
            id:'0',
          },
          ...res.categoryThirdList
        ]

            // this.categoryList = res.categoryThirdList;

        if(firstCategoryId == '0'){
          this.firstCategoryId = res.categoryFirstList.length && res.categoryFirstList[0].id;
          this.getCategory(this.firstCategoryId,this.secondCategoryId);
        }

        if(firstCategoryId != '0' && secondCategoryId == '0'){
          this.secondCategoryId = res.categorySecondList.length && res.categorySecondList[0].id;
          this.getCategory(this.firstCategoryId,this.secondCategoryId);
        }

        if (firstCategoryId != '0' && secondCategoryId != '0'){
          this.userCategory = this.categoryList.length && this.categoryList[0].name;
        }
      })
    },
    cancel(){
      this.addCategory = false;
    },
    getList(){  //获取列表数据
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword:this.keyword,
        site_id:0,
      };
      this.api.codekey.cdkeyList(params).then((res)=>{
        this.dataList = res.list;
        this.total = Number(res.count);
      })
    },
    cdkeyForm(){
      this.api.codekey.cdkeyForm().then((res)=>{
        this.statusesObj = res.statuses;
        this.statusesList = [];
        for(let name in res.statuses){
          this.statusesList.push({
            label:res.statuses[name],
            value:name
          })
        }

        this.timelongsObj = res.timelongs;
        this.timelongsList = [];
        for(let name in res.timelongs){
          this.timelongsList.push({
            label:res.timelongs[name],
            value:name
          })
        }
      })
    },
    addUser(data){
      this.curData = data;
      this.addUserModal = true;
      // this.addUserModal = true;
      // this.userMode = this.$t('codekey_list_select_user');
      // this.allUserData = [];
      // this.count = '';
      // this.curData = data;
      // this.firstCategoryId = data.category_id;
      // this.secondCategoryId = data.second_category_id;
      //
      // this.getCategory(this.firstCategoryId,this.secondCategoryId);
      //
      // this.getUserList();
    },
    // getUserList(){
    //   let params = {
    //     site_id:0,
    //     user_id:-1,
    //     page:this.userPage,
    //     pageSize:this.userPageSize,
    //     keyword: this.userKeyword,
    //     group_id:this.groupId
    //   };
    //   this.api.user.userList(params).then((res)=>{
    //     this.userDataList = res.list;
    //     this.userDataList.forEach((item)=>{
    //       let dataIndex = this.allUserData.findIndex((sItem)=>{
    //         return sItem.id == item.id;
    //       });
    //
    //       if(dataIndex != -1){
    //         item._checked = true;
    //       }
    //     });
    //     this.userTotal = Number(res.count);
    //   })
    // },
    // changeUserKeyword(){
    //   this.userPage = 1;
    //   this.getUserList();
    // },
    // changeUserPage(page){
    //   this.userPage = page;
    //   this.getUserList();
    // },
    // changeUserPageSize(pageSize){
    //   this.userPageSize = pageSize;
    //   this.getUserList();
    // },
    // cancelUserModal(){
    //   this.addUserModal = false;
    // },
    // selectUser(data){
    //   data.forEach((item)=>{
    //     let index = this.allUserData.findIndex((sItem)=>{
    //       return sItem.id == item.id;
    //     });
    //     if(index == -1){
    //       this.allUserData.push(item);
    //     }
    //   });
    // },
    // cancelUser(row,data){
    //   let dataIndex = this.allUserData.findIndex((sItem)=>{
    //     return sItem.id == data.id;
    //   });
    //   if(dataIndex != -1){
    //     this.$delete(this.allUserData,dataIndex)
    //     // this.allUserData.splice(dataIndex,1);
    //   }
    // },
    // cancelUserAll(){
    //   this.userDataList.forEach((item,index)=>{
    //     let dataIndex = this.allUserData.findIndex((sItem)=>{
    //       return sItem.id == item.id;
    //     });
    //     if(dataIndex != -1){
    //       this.$delete(this.allUserData,dataIndex)
    //       // this.allUserData.splice(index,1);
    //     }
    //   })
    // },
    // saveUser(){
    //   let categoryId = this.categoryList.filter((item)=>{
    //     return item.name == this.userCategory;
    //   })[0].id;
    //
    //   let params = {
    //     cdkey_id:this.curData.id,
    //     // category_id:categoryId,
    //   };
    //   if(categoryId == '0'){
    //     params.second_category_id = this.secondCategoryId;
    //   }else{
    //     params.category_id = categoryId;
    //   }
    //   let len = this.curData.amount - this.curData.count;
    //
    //   if(this.userMode == this.$t('codekey_list_select_user')){
    //     if(!this.allUserData.length){
    //       this.$Message.warning(this.$t('codekey_list_select_user_message'));
    //       return;
    //     }
    //     if(this.allUserData.length > len){
    //       this.$Message.warning(this.$t('codekey_list_user_num_max'));
    //       return;
    //     }
    //     params.user_ids = this.allUserData.map((item)=>{
    //       return item.id;
    //     }).join(',')
    //   }else if(this.userMode == this.$t('codekey_list_issue')){
    //     if(!this.count || this.count == '0'){
    //       this.$Message.warning(this.$t('codekey_list_num_placeholder'));
    //       return;
    //     }
    //     if(this.count > len){
    //       this.$Message.warning(this.$t('codekey_list_num_max'));
    //       return;
    //     }
    //     params.count = this.count;
    //   }
    //   this.api.codekey.cdkeyCodeCreate(params).then((res)=>{
    //     if(this.userMode == this.$t('codekey_list_select_user')){
    //       this.addUserModal = false;
    //       this.getList();
    //     }else if(this.userMode == this.$t('codekey_list_issue')){
    //       this.addUserModal = false;
    //       // this.$router.push({
    //       //   name: 'cdkey-detail',
    //       //   params: {
    //       //     id:this.curData.id,
    //       //     tab:'list'
    //       //   }
    //       // });
    //
    //       let params = {
    //         cdkey_id:this.curData.id,
    //         batch:res.batch,
    //         user_id:-1,
    //       };
    //       this.api.codekey.cdkeyCodeExport(params).then((res)=>{
    //         let uploadParams = {
    //           file:res.filepath,
    //         };
    //         this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
    //           util.downLoad(res);
    //           this.getList();
    //         })
    //       })
    //     }
    //   })
    // },
    // goInvitation(){
    //   this.$router.push({
    //     path:'/site/invitation'
    //   })
    // },
    // changeFirstCategory(data){
    //   if(this.curData.category_id !='0'){
    //
    //     return;
    //   }
    //   this.firstCategoryId = data.id;
    //   this.secondCategoryId = '0';
    //   this.userCategory = '';
    //   this.getCategory(this.firstCategoryId,this.secondCategoryId);
    // },
    // changeSecondCategory(data){
    //   if(this.curData.second_category_id != '0'){
    //
    //     return;
    //   }
    //   this.secondCategoryId = data.id;
    //   this.userCategory = '';
    //   this.getCategory(this.firstCategoryId,this.secondCategoryId);
    // },
    addSuccess(){
      this.page = 1;
      this.getList();
      this.addUserModal = false;
    },
    closeAdduser(){
      this.addUserModal = false;
    }
  }
}
</script>

<style scoped lang="scss">
.super-list{
  margin: 20px;


  .super-list-cont{
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;

    .super-list-tab{
      margin-bottom: 20px;
      display: inline-block;

      .super-list-tab-list{
        height: 38px;
        line-height: 38px;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
        >div{
          width: 150px;
          text-align: center;
          border-right: 1px solid #dcdee2;
          cursor: pointer;
        }
        >div:last-child{
          border-right:none;
        }
        .active{
          color:#FFFFFF;
          background-color: #2d8cf0;
        }
      }
    }
  }

  .top >button{
    margin-right: 20px;
  }
  .search{
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
.table{
  margin-top: 20px;
}
.page{
  margin-top: 20px;
  text-align: right;
}
.add-container{
  .tit{
    margin-bottom: 20px;
    padding: 0 20px;
    font-size: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    >p{
      margin-right: 10px;
      >span{
        color:#2d8cf0;
      }
    }
    >p:nth-child(2){
      font-size: 14px;
    }
  }
  .category-list{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    >div{
      margin-right: 10px;
      padding: 0px 10px;
      cursor: pointer;
    }
    >div.active{
      background-color: #2d8cf0;
      color:#FFFFFF;
      border-radius: 4px;
    }
  }
  .mode-cont{
    margin-top: 10px;
    padding: 20px;
    border: 1px solid #dcdee2;
    .mode-cont-tit{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .mode-cont-tit >div:nth-child(1){
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >p{
        margin-left: 10px;
      }
    }
  }
  .mode-cont-r{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .group-box{
      margin-right: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >p{
        margin-right: 10px;
      }
    }

  }
}
</style>
<style lang="scss">
.company-h .ivu-form-item{
  margin-bottom: 10px!important;
}

</style>
